import React, { useEffect, useState } from "react";
import { CampaignService } from "../services/CampaignService";
export default function Navigation() {
  const [campaignList, setCampaignList] = useState([]);
  const onLogout = () => {
    localStorage.removeItem("token");
    window.location.replace("/login");
  };
  const getCampaignList = () => {
    CampaignService.list().then((res) => {
      if (res && res?.data?.activeList) {
        setCampaignList(res?.data?.activeList);
      }
    });
  };
  useEffect(() => {
    getCampaignList();
  }, []);
  return (
    <nav
      className="navbar navbar-main navbar-expand-lg navbar-dark bg-primary navbar-border"
      id="navbar-main"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-main-collapse"
          aria-controls="navbar-main-collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse navbar-collapse-fade"
          id="navbar-main-collapse"
        >
          {campaignList && campaignList.length > 0 && (
            <ul className="navbar-nav align-items-lg-center">
              <li
                className="nav-item dropdown dropdown-animate"
                data-toggle="hover"
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Campaigns
                </a>
                <div className="dropdown-menu dropdown-menu-arrow p-lg-0">
                  <div className="p-lg-4" style={{ zIndex: "1000" }}>
                    <div
                      className="dropdown dropdown-animate"
                      data-toggle="hover"
                    >
                      {campaignList.map((c, i) => {
                        const url = `/${c._id}/dashboard`;
                        return (
                          <a key={i} href={url} className="dropdown-item">
                            {c.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          )}
          <ul className="navbar-nav ml-lg-auto align-items-center d-none d-lg-flex">
            <li className="nav-item">
              <a
                href="/"
                className="nav-link nav-link-icon sidenav-toggler"
                data-action="sidenav-pin"
                data-target="#sidenav-main"
              >
                <i className="fas fa-bars"></i>
              </a>
            </li>

            <li className="nav-item dropdown dropdown-animate">
              <a
                className="nav-link pr-lg-0"
                href="/"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="media media-pill align-items-center">
                  <span className="avatar rounded-circle">
                    <img
                      loading="lazy"
                      alt=" placeholder"
                      src="https://d332p72m5y8ncz.cloudfront.net/1703504574993-mesh.jpg"
                    />
                  </span>
                  <div className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm  font-weight-bold">
                      Mesh Marketing
                    </span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow">
                <h6 className="dropdown-header px-0">Hi, Mesh!</h6>

                <div className="dropdown-divider"></div>
                <button onClick={onLogout} className="dropdown-item">
                  <i className="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
