import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CampaignContext } from "../context/CampaignContext";
import { CampaignTypes } from "../constants/CampaignType";

const INITIAL_TABS = {
  home: "btn btn-square text-sm ",
  addresses: "btn btn-square text-sm",
  users: "btn btn-square text-sm",
  retailers: "btn btn-square text-sm",
  settings: "btn btn-square text-sm",
  branding: "btn btn-square text-sm",
  translation: "btn btn-square text-sm",
  email: "btn btn-square text-sm",
  serves: "btn btn-square text-sm",
  game: "btn btn-square text-sm",
  prediction: "btn btn-square text-sm",
  instantWin: "btn btn-square text-sm",
  loyalty: "btn btn-square text-sm",
};

export default function Header({ activePage }) {
  const { campaign } = useContext(CampaignContext);
  const [tabs, setTabs] = useState(INITIAL_TABS);

  useEffect(() => {
    if (activePage.includes("/dashboard")) {
      setTabs({
        ...INITIAL_TABS,
        home: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/address")) {
      setTabs({
        ...INITIAL_TABS,
        addresses: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/users")) {
      setTabs({
        ...INITIAL_TABS,
        users: "btn btn-square text-sm active",
      });
    } else if (
      activePage.includes("/retailers") ||
      activePage.includes("/retailer")
    ) {
      setTabs({
        ...INITIAL_TABS,
        retailers: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/settings")) {
      setTabs({
        ...INITIAL_TABS,
        settings: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/branding")) {
      setTabs({
        ...INITIAL_TABS,
        branding: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/email")) {
      setTabs({
        ...INITIAL_TABS,
        email: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/serves")) {
      setTabs({
        ...INITIAL_TABS,
        serves: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/game-settings")) {
      setTabs({
        ...INITIAL_TABS,
        game: "btn btn-square text-sm active",
      });
    } else if (
      activePage.includes("/prediction") ||
      activePage.includes("/prediction/translation")
    ) {
      setTabs({
        ...INITIAL_TABS,
        prediction: "btn btn-square text-sm active",
      });
    } else if (
      activePage.includes("/loyalty") ||
      activePage.includes("/loyalty/translation")
    ) {
      setTabs({
        ...INITIAL_TABS,
        loyalty: "btn btn-square text-sm active",
      });
    }
    else if (activePage.includes("/instant-win")) {
      setTabs({
        ...INITIAL_TABS,
        instantWin: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/translation")) {
      setTabs({
        ...INITIAL_TABS,
        translation: "btn btn-square text-sm active",
      });
    }
  }, [activePage]);

  return (
    <>
      {campaign && (
        <div className="sidenav show" id="sidenav-main">
          <div className="sidenav-header d-flex align-items-center">
            <a className="navbar-brand" href="/">
              <img
                loading="lazy"
                src="https://d4nck9avwxta9.cloudfront.net/atscale_logo.png"
                className="navbar-brand-img"
                alt="..."
              />
            </a>
            <div className="ml-auto">
              <div
                className="sidenav-toggler sidenav-toggler-dark d-md-none"
                data-action="sidenav-pin"
                data-target="#sidenav-main"
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line bg-white"></i>
                  <i className="sidenav-toggler-line bg-white"></i>
                  <i className="sidenav-toggler-line bg-white"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="sidenav-user d-flex flex-column align-items-center justify-content-between text-center">
            <div>
              <a href="/" className="avatar rounded-circle avatar-xl">
                <img
                  loading="lazy"
                  alt=" placeholder"
                  src="https://d332p72m5y8ncz.cloudfront.net/1703504574993-mesh.jpg"
                />
              </a>
              <div className="mt-4">
                <h5 className="mb-0 text-white">
                  {campaign && campaign.name ? campaign.name : "-"}
                </h5>
                <span className="d-block text-sm text-white opacity-8 mb-3">
                  Admin
                </span>
                <a
                  href={campaign.campaignDomain}
                  target="_blank"
                  className="toSite"
                  rel="noreferrer"
                >
                  <i className="fas fa-globe text-white"></i>
                  <span className="toSite-text"> See live site</span>
                </a>
              </div>
            </div>
          </div>
          <div className="nav-application clearfix">
            <Link
              to={{
                pathname: `/${campaign._id}/dashboard`,
              }}
              className={tabs.home}
            >
              <span className="btn-inner--icon d-block">
                <i className="fas fa-home fa-2x"></i>
              </span>
              <span className="btn-inner--icon d-block pt-2">Home</span>
            </Link>
            <Link
              to={{ pathname: `/${campaign._id}/retailers` }}
              className={tabs.retailers}
            >
              <span className="btn-inner--icon d-block">
                <i className="fas fa-store fa-2x"></i>
              </span>
              <span className="btn-inner--icon d-block pt-2">Retailers</span>
            </Link>
            <Link
              to={{
                pathname: `/${campaign._id}/users`,
              }}
              className={tabs.users}
            >
              <span className="btn-inner--icon d-block">
                <i className="fas fa-users fa-2x"></i>
              </span>
              <span className="btn-inner--icon d-block pt-2">Users</span>
            </Link>

            <Link
              to={{
                pathname: `/${campaign._id}/settings`,
              }}
              className={tabs.settings}
            >
              <span className="btn-inner--icon d-block">
                <i className="fas fa-cog fa-2x"></i>
              </span>
              <span className="btn-inner--icon d-block pt-2">Settings</span>
            </Link>
            <Link
              to={{
                pathname: `/${campaign._id}/branding`,
              }}
              className={tabs.branding}
            >
              <span className="btn-inner--icon d-block">
                <i className="fas fa-magic fa-2x"></i>
              </span>
              <span className="btn-inner--icon d-block pt-2">Branding</span>
            </Link>
            <Link
              to={{
                pathname: `/${campaign._id}/translation`,
              }}
              className={tabs.translation}
            >
              <span className="btn-inner--icon d-block">
                <i className="fas fa-font fa-2x"></i>
              </span>
              <span className="btn-inner--icon d-block pt-2">Translations</span>
            </Link>

            <Link
              to={{
                pathname: `/${campaign._id}/email`,
              }}
              className={tabs.email}
            >
              <span className="btn-inner--icon d-block">
                <i className="fas fa-envelope fa-2x"></i>
              </span>
              <span className="btn-inner--icon d-block pt-2">Email</span>
            </Link>
            <Link
              to={{
                pathname: `/${campaign._id}/serves`,
              }}
              className={tabs.serves}
            >
              <span className="btn-inner--icon d-block">
                <i className="fas fa-glass-martini fa-2x"></i>
              </span>
              <span className="btn-inner--icon d-block pt-2">Serves</span>
            </Link>
            {(campaign?.type === CampaignTypes.GAME_AT_THE_BEGINNING ||
              campaign?.type === CampaignTypes.GAME_AT_THE_END) && (
              <Link
                to={{
                  pathname: `/${campaign._id}/game-settings`,
                }}
                className={tabs.game}
              >
                <span className="btn-inner--icon d-block">
                  <i className="fas fa-gamepad fa-2x"></i>
                </span>
                <span className="btn-inner--icon d-block pt-2">Game</span>
              </Link>
            )}
            {campaign?.type === CampaignTypes.PREDICTION && (
              <Link
                to={{
                  pathname: `/${campaign._id}/prediction`,
                }}
                className={tabs.prediction}
              >
                <span className="btn-inner--icon d-block">
                  <i className="fa-2x">
                    <i className="fas fa-thumbs-up"></i>
                    <i
                      className="fas fa-thumbs-up"
                      style={{ rotate: "180deg" }}
                    ></i>
                  </i>
                </span>
                <span className="btn-inner--icon d-block pt-2">Prediction</span>
              </Link>
            )}
            {campaign?.type === CampaignTypes.LOYALTY && (
              <Link
                to={{
                  pathname: `/${campaign._id}/loyalty`,
                }}
                className={tabs.loyalty}
              >
                <span className="btn-inner--icon d-block">
                  <i className="fa-2x">
                    <i className="fa fa-stamp" aria-hidden="true"></i>
                  </i>
                </span>
                <span className="btn-inner--icon d-block pt-2">Loyalty</span>
              </Link>
            )}
            {campaign?.journey?.instantWin && (
              <Link
                to={{
                  pathname: `/${campaign._id}/instant-win`,
                }}
                className={tabs.instantWin}
              >
                <span className="btn-inner--icon d-block">
                  <i className="fas fa-trophy fa-2x"></i>
                </span>
                <span className="btn-inner--icon d-block pt-2">
                  Instant Win
                </span>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
}
