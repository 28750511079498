import React from "react";
import { Button, Card } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
export default function GameMainSettings({ campaign }) {
  return (
    <>
      <>
        <Card.Header className="card-header actions-toolbar border-0">
          <h5 className=" h6 mb-0">Game main settings</h5>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Api Key</Form.Label>
              <div className="d-flex flex-row justify-items-center align-items-center">
                <Form.Control
                  id="apiKeyPass"
                  type="text"
                  readOnly
                  defaultValue={campaign?.game?.apiKey}
                />
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(campaign?.game?.apiKey);
                    toast.success("Copied to clipboard");
                  }}
                >
                  <i className="fas fa-copy"></i>
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Card.Body>
      </>
    </>
  );
}
