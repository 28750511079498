import React, { useState } from "react";
import GameDetailsTab from "./GameDetailsTab";
import { GameDetailsTabType } from "../../constants/GameDetailsTabType";
import GameSettings from "./GameSettings";
import GameTranslations from "./GameTranslations";

export default function GameDetails() {
  const [defaultTab, setDefaultTab] = useState(GameDetailsTabType.SETTINGS);

  return (
    <>
      <GameDetailsTab defaultTab={defaultTab} setDefaultTab={setDefaultTab} />
      {defaultTab === GameDetailsTabType.SETTINGS && <GameSettings />}
      {defaultTab === GameDetailsTabType.TRANSLATIONS && <GameTranslations />}
    </>
  );
}
