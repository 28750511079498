import { Suspense, useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { CardWrapper } from "../CardWrapper";
import TranslationList from "../translation/TranslationList";
import { CampaignService } from "../../services/CampaignService";
import { useNavigate, useParams } from "react-router-dom";
import { PageContext } from "../../context/PageContext";

export default function GameTranslations() {
  const params = useParams();
  const navigate = useNavigate();
  const [pages, setPages] = useState([]);
  const { setPageId } = useContext(PageContext);

  const getPredictionPages = () => {
    CampaignService.getPages("game", params.id).then((res) => {
      if (res && res?.data) {
        setPages(res?.data);
        setPageId(res?.data?._id);
      }
    });
  };

  useEffect(() => {
    getPredictionPages();
  }, []);

  const onNavigate = (page) => {
    navigate(`/${params?.id}/game/translation/${page}`, {
      state: {
        groupName: "game",
      },
    });
  };

  return (
    <Card>
      <Suspense fallback={<CardWrapper />}>
        <TranslationList
          pageList={pages["game"]}
          groupName={"game"}
          onNavigate={onNavigate}
        />
      </Suspense>
    </Card>
  );
}
