import { Suspense, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { LoadingWrapper } from "./LoadingWrapper";
import { MainHeader } from "./MainHeader";
import { MainNavigation } from "./MainNavigation";

export default function PrivateDashboardRoute({ children }) {
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      return <Navigate to="/login" replace />;
    }
  }, []);

  return (
    <div className="container-fluid container-application">
      <MainHeader activePage={window.location.pathname}></MainHeader>
      <div className="main-content position-relative">
        <MainNavigation></MainNavigation>

        <div className="page-content">
          <Suspense fallback={<LoadingWrapper />}>{children}</Suspense>
        </div>
      </div>
    </div>
  );
}
