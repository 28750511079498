import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import CampaignContextProvider from "./context/CampaignContext";
import PageContextProvider from "./context/PageContext";
import "react-toggle/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <CampaignContextProvider>
      <PageContextProvider>
        <ToastContainer />
        <App />
      </PageContextProvider>
    </CampaignContextProvider>
  </BrowserRouter>
);
