import React, { useContext, useEffect } from "react";
import { CampaignContext } from "../context/CampaignContext";
import { useNavigate, useParams } from "react-router-dom";

import { CampaignTypes } from "../constants/CampaignType";
import GameDetails from "../components/game/GameDetails";

export default function Game() {
  const { campaign } = useContext(CampaignContext);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      campaign?.type &&
      campaign?.type !== CampaignTypes.GAME_AT_THE_BEGINNING &&
      campaign?.type !== CampaignTypes.GAME_AT_THE_END
    ) {
      navigate(`/${params?.id}/dashboard`);
    }
  }, [campaign]);

  return (
    <div>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">
                Game
              </h5>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end"></div>
        </div>
      </div>
      <GameDetails />
    </div>
  );
}
