export const PageTypes = [
  {
    id: "age_gate",
    title: "Age gate",
  },
  {
    id: "register",
    title: "Registration",
  },
  {
    id: "select_location",
    title: "Select a location",
  },
  {
    id: "thank_you",
    title: "Thank you for registering",
  },
  {
    id: "new_location",
    title: "Select a new location",
  },
  {
    id: "errors_list",
    title: "Error pages",
  },
  {
    id: "wrong_location",
    title: "Wrong location",
  },
  {
    id: "cookie_policy",
    title: "Cookie Policy",
  },
  {
    id: "privacy_policy",
    title: "Privacy Policy",
  },
  {
    id: "terms_condition",
    title: "Terms & Conditions",
  },
  {
    id: "swipe_to_redeem",
    title: "Swipe to redeem",
  },
  {
    id: "voucher",
    title: "Voucher",
  },
  {
    id: "voucher_redeemed",
    title: "Voucher redeemed",
  },
  {
    id: "resend_email",
    title: "Resend email",
  },
  {
    id: "faq",
    title: "FAQs",
  },
  {
    id: "mobile_only",
    title: "Mobile Only",
  },
  {
    id: "closed_page",
    title: "Closed Page",
  },
  {
    id: "instant_win",
    title: "Instant Win Page",
  },
  {
    id: "game_settings",
    title: "Game  Page",
  },
  {
    id: "no_win",
    title: "No Win",
  },
  {
    id: "too_early",
    title: "Too Early",
  },
  {
    id: "loading",
    title: "Loading",
  },
  {
    id: "claim_prize",
    title: "Claim Prize",
  },
  {
    id: "prize_redeemed",
    title: "Prize Redeemed",
  },
  {
    id: "already_redeemed",
    title: "Already redeemed",
  },
  {
    id: "link_expired",
    title: "Link expired",
  },
  {
    id: "prediction",
    title: "Prediction",
  },
  {
    id: "summary",
    title: "Summary",
  },
  {
    id: "game_not_started",
    title: "Game not started",
  },
  {
    id: "game_in_progress",
    title: "Game in progress",
  },
  {
    id: "win",
    title: "Win",
  },
  {
    id: "thanks",
    title: "Thanks",
  },
  {
    id: "swipe_step",
    title: "Swipe step",
  },
  {
    id: "step_swiped",
    title: "Step swiped",
  },
  {
    id: "loyalty_completed",
    title: "Loyalty completed",
  },
  {
    id: "loyalty_continue",
    title: "Loyalty continue",
  },
  {
    id: "come_back_later",
    title: "Loyalty active journey",
  },
];
