import React, { createContext, useState } from "react";

export const PageContext = createContext();

const PageContextProvider = ({ children }) => {
  const [translationPage, setTranslationPage] = useState();
  const [pageId, setPageId] = useState();

  return (
    <PageContext.Provider
      value={{ translationPage, setTranslationPage, pageId, setPageId }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContextProvider;
