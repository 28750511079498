import { toast } from "react-toastify";
import { Endpoints } from "./Endpoints";
const baseUrl = `${process.env.REACT_APP_API_URL}/api/`;
export class CampaignService {
  static getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    };
  }

  static async list() {
    const requestOptions = {
      method: "GET",
      headers: this.getHeaders(),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.LIST_CAMPAIGNS,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not get campaigns.");
      return error.message;
    }
  }

  static async create(data) {
    const requestOptions = {
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(data),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.LIST_CAMPAIGNS,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not create campaign.");
      return error.message;
    }
  }

  static async duplicate(data) {
    const requestOptions = {
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(data),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.DUPLICATE_CAMPAIGN,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not duplicate campaign.");
      return error.message;
    }
  }

  static async getCampaignById(id) {
    const requestOptions = {
      method: "GET",
      headers: this.getHeaders(),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.GET_CAMPAIGN_BY_ID + `/${id}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not find campaign.");
      return error.message;
    }
  }

  static async reports(id) {
    const requestOptions = {
      method: "GET",
      headers: this.getHeaders(),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.GET_REPORTS + `/${id}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not get reports.");
      return error.message;
    }
  }

  static async updateCampaign(type, payload) {
    const requestOptions = {
      method: "PUT",
      headers: this.getHeaders(),
      body: JSON.stringify(payload),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.UPDATE_CAMPAIGN + `/${payload.id}/${type}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not update campaign.");
      return error.message;
    }
  }

  static async updateDailyLimit(payload) {
    const requestOptions = {
      method: "PUT",
      headers: this.getHeaders(),
      body: JSON.stringify(payload),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.UPDATE_DAILY_LIMIT + `/${payload.id}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not update daily limit.");
      return error.message;
    }
  }

  static async updateCountdownTimer(payload) {
    const requestOptions = {
      method: "PUT",
      headers: this.getHeaders(),
    };
    try {
      const respJson = await fetch(
        baseUrl +
          Endpoints.UPDATE_COUNTDOWN_TIMER +
          `/${payload.id}/${payload.countdownTimer}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not update countdown timer.");
      return error.message;
    }
  }

  static async updateArchiveStatus(payload) {
    const requestOptions = {
      method: "PUT",
      headers: this.getHeaders(),
    };
    try {
      const respJson = await fetch(
        baseUrl +
          Endpoints.UPDATE_ARCHIVE_STATUS +
          `/${payload.id}/${payload.type}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not update campaign status.");
      return error.message;
    }
  }

  static async delete(id) {
    const requestOptions = {
      method: "DELETE",
      headers: this.getHeaders(),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.DELETE_CAMPAIGN + `/${id}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not delete campaign.");
      return error.message;
    }
  }

  static async updateEmail(payload) {
    const requestOptions = {
      method: "PUT",
      headers: this.getHeaders(),
      body: JSON.stringify(payload.body),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.UPDATE_EMAIL + `/${payload.id}/${payload.type}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not update email.");
      return error.message;
    }
  }

  static async updateReminderEmail(payload) {
    const requestOptions = {
      method: "PUT",
      headers: this.getHeaders(),
      body: JSON.stringify(payload.body),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.UPDATE_REMINDER_EMAIL + `/${payload.id}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not update email.");
      return error.message;
    }
  }

  static async updateBranding(payload) {
    const requestOptions = {
      method: "PUT",
      headers: this.getHeaders(),
      body: JSON.stringify(payload.body),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.UPDATE_BRANDING + `/${payload.id}/${payload.type}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not update branding.");
      return error.message;
    }
  }

  static async getPages(pageType, id) {
    const requestOptions = {
      method: "GET",
      headers: this.getHeaders(),
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.LIST_PAGES + `/${pageType}/${id}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not get pages.");
      return error.message;
    }
  }

  static async updatePage(payload) {
    const requestOptions = {
      method: "PUT",
      headers: this.getHeaders(),
      body: JSON.stringify(payload.body),
    };
    try {
      const respJson = await fetch(
        baseUrl +
          Endpoints.UPDATE_PAGE +
          `/${payload.id}/${payload.pageName}/${payload?.type}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not update page.");
      return error.message;
    }
  }

  static async getCampaignServes(campaignId) {
    const requestOptions = {
      method: "get",
      headers: this.getHeaders(),
      body: null,
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.CAMPAIGN_SERVES + `/campaign/${campaignId}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not get campaign serves.");
      return error.message;
    }
  }

  static async getAvailableServes(campaignId) {
    const requestOptions = {
      method: "get",
      headers: this.getHeaders(),
      body: null,
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.CAMPAIGN_SERVES + `/${campaignId}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not get available serves.");
      return error.message;
    }
  }

  static async addServe(campaignId, serveId) {
    const requestOptions = {
      method: "POST",
      headers: this.getHeaders(),
      body: null,
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.CAMPAIGN_SERVES + `/${campaignId}/${serveId}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not add serve.");
      return error.message;
    }
  }

  static async deleteServe(campaignId, serveId) {
    const requestOptions = {
      method: "DELETE",
      headers: this.getHeaders(),
      body: null,
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.CAMPAIGN_SERVES + `/${campaignId}/${serveId}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not delete serve.");
      return error.message;
    }
  }

  static async getCsvTemplate(campaignId) {
    const requestOptions = {
      method: "GET",
      headers: this.getHeaders(),
      body: null,
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.CAMPAIGN_VENUES_CSV_TEMPLATE + `/${campaignId}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not get csv template.");
      return error.message;
    }
  }

  static async getReminderEmailStats(campaignId) {
    const requestOptions = {
      method: "GET",
      headers: this.getHeaders(),
      body: null,
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.CAMPAIGN_REMINDER_EMAIL_STATS + `/${campaignId}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not get reports stats.");
      return error.message;
    }
  }
  static async sendReminderEmails(campaignId) {
    const requestOptions = {
      method: "POST",
      headers: this.getHeaders(),
      body: null,
    };
    try {
      const respJson = await fetch(
        baseUrl + Endpoints.CAMPAIGN_REMINDER_EMAIL_SEND + `/${campaignId}`,
        requestOptions
      );
      return await respJson.json();
    } catch (error) {
      toast.error("Could not send reminder emails.");
      return error.message;
    }
  }
}
