import React from "react";
import { routeNames } from "./routeNames";
import PrivateRoute from "./components/privateRoute";
import PrivateDashboardRoute from "./components/privateDashRoute";
import { Navigate } from "react-router-dom";
import Game from "./containers/Game";
const Login = React.lazy(() => import("./containers/Login"));
const MainCampaign = React.lazy(() => import("./containers/MainCampaign"));
const Dashboard = React.lazy(() => import("./containers/Dashboard"));
const Users = React.lazy(() => import("./containers/Users"));
const Settings = React.lazy(() => import("./containers/Settings"));
const Email = React.lazy(() => import("./containers/Email"));
const Branding = React.lazy(() => import("./containers/Branding"));
const Translation = React.lazy(() => import("./containers/Translation"));
const Serves = React.lazy(() => import("./containers/Serves"));
const ExternalInstantWin = React.lazy(() =>
  import("./containers/ExternalInstantWin")
);
const TranslationPage = React.lazy(() =>
  import("./components/translation/TranslationPage")
);
const Retailer = React.lazy(() => import("./containers/Retailer"));
const CampaignServes = React.lazy(() => import("./containers/CampaignServes"));
const CampaignInstantWin = React.lazy(() =>
  import("./containers/CampaignInstantWin")
);
const Prediction = React.lazy(() => import("./containers/Prediction"));
const GroupDetail = React.lazy(() =>
  import("./components/instant-win/groups/group-detail/GroupDetail")
);
const RetailerDetail = React.lazy(() =>
  import("./components/retailer/RetailerDetail")
);
const Loyalty = React.lazy(() => import("./containers/Loyalty"));

export const myRoutes = [
  {
    path: routeNames.LOGIN,
    element: <Login />,
    name: "Login",
    exact: true,
  },
  {
    path: routeNames.HOME,
    element: (
      <PrivateDashboardRoute>
        <MainCampaign />
      </PrivateDashboardRoute>
    ),
    name: "Main",
    exact: true,
  },
  {
    path: routeNames.SERVES,
    element: (
      <PrivateDashboardRoute>
        <Serves />
      </PrivateDashboardRoute>
    ),
    name: "Serves",
    exact: true,
  },
  {
    path: routeNames.DASHBOARD,
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    name: "Dashboard",
    exact: true,
  },
  {
    path: routeNames.USERS,
    element: (
      <PrivateRoute>
        <Users />
      </PrivateRoute>
    ),
    name: "Dashboard",
    exact: true,
  },
  {
    path: routeNames.SETTINGS,
    element: (
      <PrivateRoute>
        <Settings />
      </PrivateRoute>
    ),
    name: "Settings",
    exact: true,
  },
  {
    path: routeNames.EMAIL,
    element: (
      <PrivateRoute>
        <Email />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.BRANDING,
    element: (
      <PrivateRoute>
        <Branding />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.TRANSLATION,
    element: (
      <PrivateRoute>
        <Translation />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.TRANSLATION_PAGE,
    element: (
      <PrivateRoute>
        <TranslationPage />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.RETAILERS,
    element: (
      <PrivateRoute>
        <Retailer />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.RETAILER,
    element: (
      <PrivateRoute>
        <RetailerDetail />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.CAMPAIGN_SERVES,
    element: (
      <PrivateRoute>
        <CampaignServes />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.CAMPAIGN_INSTANT_WIN,
    element: (
      <PrivateRoute>
        <CampaignInstantWin />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.CAMPAIGN_INSTANT_WIN_GROUP,
    element: (
      <PrivateRoute>
        <GroupDetail />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.CAMPAIGN_INSTANT_WIN_TRANSLATION_PAGE,
    element: (
      <PrivateRoute>
        <TranslationPage />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.GAME_TRANSLATION_PAGE,
    element: (
      <PrivateRoute>
        <TranslationPage />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.INSTANT_WIN,
    element: (
      <PrivateRoute>
        <ExternalInstantWin />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.GAME_SETTINGS,
    element: (
      <PrivateRoute>
        <Game />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.PREDICTION,
    element: (
      <PrivateRoute>
        <Prediction />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.PREDICTION_TRANSLATION_PAGE,
    element: (
      <PrivateRoute>
        <TranslationPage />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.LOYALTY,
    element: (
      <PrivateRoute>
        <Loyalty />
      </PrivateRoute>
    ),
  },
  {
    path: routeNames.LOYALTY_TRANSLATION_PAGE,
    element: (
      <PrivateRoute>
        <TranslationPage />
      </PrivateRoute>
    ),
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
];
