import { PageTypes } from "../constants/pageTypes";

class PageHelper {
  static getPageTitle(id) {
    let findedPageTitle = null;
    PageTypes.forEach((page) => {
      if (page.id === id) {
        findedPageTitle = page.title;
      }
    });
    return findedPageTitle;
  }
}
export default PageHelper;
