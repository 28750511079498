import { useLocation } from "react-router-dom";
import { GameDetailsTabType } from "../../constants/GameDetailsTabType";
import { useEffect } from "react";

export default function GameDetailsTab({ defaultTab, setDefaultTab }) {
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.tab === GameDetailsTabType.TRANSLATIONS) {
      setDefaultTab(GameDetailsTabType.TRANSLATIONS);
    }
  }, [location.state]);

  return (
    <ul className="nav nav-dark nav-tabs nav-overflow">
      <li className="nav-item">
        <label
          onClick={() => {
            setDefaultTab(GameDetailsTabType.SETTINGS);
          }}
          className={
            defaultTab === GameDetailsTabType.SETTINGS
              ? "nav-link active mb-0"
              : "nav-link mb-0"
          }
        >
          <i className="fas fa-cog mr-2"></i>Settings
        </label>
      </li>
      <li className="nav-item">
        <label
          onClick={() => {
            setDefaultTab(GameDetailsTabType.TRANSLATIONS);
          }}
          className={
            defaultTab === GameDetailsTabType.TRANSLATIONS
              ? "nav-link active mb-0"
              : "nav-link mb-0"
          }
        >
          <i className="fas fa-font mr-2"></i>Translations
        </label>
      </li>
    </ul>
  );
}
