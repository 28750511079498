import React, { useEffect, useState } from "react";

export const MainHeader = ({ activePage }) => {
  const [tabs, setTabs] = useState({
    home: "btn btn-square text-sm active",
    addresses: "btn btn-square text-sm",
    users: "btn btn-square text-sm",
    retailers: "btn btn-square text-sm",
    campaign: "btn btn-square text-sm",
    serves: "btn btn-square text-sm ",
  });

  useEffect(() => {
    if (activePage.includes("/address")) {
      setTabs({
        home: "btn btn-square text-sm",
        addresses: "btn btn-square text-sm active",
        users: "btn btn-square text-sm",
        retailers: "btn btn-square text-sm",
      });
    } else if (activePage === "/users") {
      setTabs({
        home: "btn btn-square text-sm",
        addresses: "btn btn-square text-sm",
        users: "btn btn-square text-sm active",
        retailers: "btn btn-square text-sm",
      });
    } else if (activePage === "/serves") {
      setTabs({
        home: "btn btn-square text-sm",
        addresses: "btn btn-square text-sm",
        users: "btn btn-square text-sm ",
        retailers: "btn btn-square text-sm",
        serves: "btn btn-square text-sm active",
      });
    } else if (
      activePage.includes("/retailers") ||
      activePage.includes("/retailer")
    ) {
      setTabs({
        home: "btn btn-square text-sm",
        addresses: "btn btn-square text-sm",
        users: "btn btn-square text-sm",
        retailers: "btn btn-square text-sm active",
      });
    } else if (activePage.includes("/campaign")) {
      setTabs({
        home: "btn btn-square text-sm",
        addresses: "btn btn-square text-sm",
        users: "btn btn-square text-sm",
        retailers: "btn btn-square text-sm",
        campaign: "btn btn-square text-sm active",
      });
    }
  }, [activePage]);

  return (
    <div className="sidenav show" id="sidenav-main">
      <div className="sidenav-header d-flex align-items-center">
        <a className="navbar-brand" href="/">
          <img
            loading="lazy"
            src="https://d4nck9avwxta9.cloudfront.net/atscale_logo.png"
            className="navbar-brand-img"
            alt="..."
          />
        </a>
        <div className="ml-auto">
          <div
            className="sidenav-toggler sidenav-toggler-dark d-md-none"
            data-action="sidenav-pin"
            data-target="#sidenav-main"
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line bg-white"></i>
              <i className="sidenav-toggler-line bg-white"></i>
              <i className="sidenav-toggler-line bg-white"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="sidenav-user d-flex flex-column align-items-center justify-content-between text-center">
        <div>
          <a href="/" className="avatar rounded-circle avatar-xl">
            <img loading="lazy" src="https://d332p72m5y8ncz.cloudfront.net/1703504574993-mesh.jpg" alt="mesh logo" />
          </a>
          <div className="mt-4">
            <h5 className="mb-0 text-white">Mesh Marketing</h5>
            <span className="d-block text-sm text-white opacity-8 mb-3">
              Admin
            </span>
          </div>
        </div>
      </div>
      <div className="nav-application clearfix">
        <a href="/" className={tabs.home}>
          <span className="btn-inner--icon d-block">
            <i className="fas fa-home fa-2x"></i>
          </span>
          <span className="btn-inner--icon d-block pt-2">Active Campaigns</span>
        </a>
        <a href="/serves" className={tabs.serves}>
          <span className="btn-inner--icon d-block">
            <i className="fas fa-glass-martini fa-2x"></i>
          </span>
          <span className="btn-inner--icon d-block pt-2">Serves</span>
        </a>
      </div>
    </div>
  );
};
