import React, { Suspense, useContext, useEffect, useState } from "react";
import { CampaignContext } from "../../context/CampaignContext";
import { CardWrapper } from "../../components/CardWrapper";
import { CampaignService } from "../../services/CampaignService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import GameEmbedSettings from "../../components/game/GameEmbedSettings";
import GameMainSettings from "../../components/game/GameMainSettings";

export default function GameSettings() {
  const { campaign, setCampaign } = useContext(CampaignContext);
  const params = useParams();
  const [activeTab, setActiveTab] = useState("embedCode");
  const [tabs, setTabs] = useState({
    settingsTab: "list-group-item active",
    embedCodeTab: "list-group-item",
  });

  useEffect(() => {
    const tabObject = {
      tab: activeTab,
      settingsTab: "list-group-item",
      embedCodeTab: "list-group-item",
    };
    switch (tabObject.tab) {
      case "settings":
        tabObject.settingsTab = "list-group-item active";
        break;
      case "embedCode":
        tabObject.embedCodeTab = "list-group-item active";
        break;
      default:
        tabObject.settingsTab = "list-group-item active";
        break;
    }
    setTabs(tabObject);
  }, [activeTab]);

  const updateCampaign = (type, data) => {
    data.id = params?.id;
    CampaignService.updateCampaign(type, data)
      .then((res) => {
        if (res && res.result) {
          toast.success("Game settings updated successfully.");
        }
      })
      .finally(() => {
        CampaignService.getCampaignById(data.id).then((res) => {
          setCampaign(res?.data);
        });
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-4 order-lg-2">
          <div className="card">
            <div className="list-group list-group-flush">
              <div className={tabs.embedCodeTab}>
                <div className="media">
                  <i className="fas fa-code"></i>
                  <div className="media-body ml-3">
                    <a
                      role={"button"}
                      onClick={() => {
                        setActiveTab("embedCode");
                      }}
                      className="stretched-link h6 mb-1"
                    >
                      Game url
                    </a>
                    <p className="mb-0 text-sm"></p>
                  </div>
                </div>
              </div>
              <div className={tabs.settingsTab}>
                <div className="media">
                  <i className="fas fa-cog"></i>
                  <div className="media-body ml-3">
                    <a
                      role={"button"}
                      onClick={() => {
                        setActiveTab("settings");
                      }}
                      className="stretched-link h6 mb-1"
                    >
                      Settings
                    </a>
                    <p className="mb-0 text-sm"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 order-lg-1">
          <Card>
            <Suspense fallback={<CardWrapper />}>
              {activeTab === "settings" && (
                <GameMainSettings campaign={campaign} />
              )}
              {activeTab === "embedCode" && (
                <GameEmbedSettings
                  campaign={campaign}
                  onUpdate={updateCampaign}
                />
              )}
            </Suspense>
          </Card>
        </div>
      </div>
    </>
  );
}
