export const Endpoints = {
  LOGIN: "admin/login",
  LIST_USERS: "admin/list",
  REDEMPTION_LIST: "admin/redemption",
  EXPORT_BY_CAMPAIGN_ID: "admin/campaign/user-list/export",
  REMOVE_USER: "admin/user",
  RESEND_EMAIL: "admin/user/resend-email",
  LIST_CAMPAIGNS: "campaign",
  GET_CAMPAIGN_BY_ID: "campaign",
  GET_REPORTS: "campaign/reports",
  UPDATE_CAMPAIGN: "campaign",
  UPDATE_DAILY_LIMIT: "campaign/limit",
  UPDATE_COUNTDOWN_TIMER: "campaign/timer",
  UPDATE_ARCHIVE_STATUS: "campaign/status",
  DELETE_CAMPAIGN: "campaign/delete",
  UPDATE_EMAIL: "campaign/email",
  UPDATE_REMINDER_EMAIL: "campaign/reminder-email",
  UPDATE_BRANDING: "campaign/branding",
  DUPLICATE_CAMPAIGN: "/campaign/duplicate",
  UPLOAD_IMAGE: "address/upload",
  LIST_PAGES: "campaign/pages",
  UPDATE_PAGE: "campaign/pages",
  LIST_RETAILERS: "retailer/list",
  CREATE_RETAILER: "retailer",
  GET_RETAILER_BY_ID: "retailer",
  GET_RETAILER_REPORTS: "admin/reports/retailer",
  LIST_BY_RETAILER: "admin/list-retailer",
  EXPORT_BY_RETAILER: "admin/retailer/user-list/export",
  UPDATE_RETAILER: "retailer",
  UPDATE_RETAILER_STATUS: "retailer/status",
  DELETE_RETAILER: "retailer",
  GET_CODES_BY_RETAILER: "retailer/unique-codes",
  CREATE_UNIQUE_CODES: "retailer/unique-codes",
  GET_GENERIC_IMAGES: "retailer/genericimage",
  CREATE_GENERIC_IMAGES: "retailer/genericimage",
  DELETE_GENERIC_IMAGE: "retailer/genericimage",
  UPDATE_GENERIC_IMAGE: "retailer/genericimage",
  CHECK_PRIZE_EXISTENCE_BY_RETAILER: "retailer/prizes",
  GET_VENUES: "address",
  EXPORT_VENUES: "admin/export/venues",
  UPDATE_ADDRESS_STATUS: "address/available",
  UPDATE_ADDRESS: "address",
  DELETE_ADDRESS: "address",
  CREATE_ADDRESS: "address",
  CREATE_ADDRESS_BATCH: "address/batch",
  CAMPAIGN_SERVES: "campaign/serves",
  CAMPAIGN_VENUES_CSV_TEMPLATE: "campaign/templates/csv",
  SERVES: "serve",
  PRIZES: "instant-win/prize",
  MOMENTS: "instant-win/moment",
  GROUPS: "instant-win/group",
  CAMPAIGN_REMINDER_EMAIL_STATS: "campaign/reminder-email/stats",
  CAMPAIGN_REMINDER_EMAIL_SEND: "campaign/reminder-email/send",
  PREDICTION_GAME: "prediction/game",
};
