import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { myRoutes } from "./Routes";
export const App = () => {
  return (
    <>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          {myRoutes.map((r, i) => {
            return <Route path={r.path} key={i} element={r.element} />;
          })}
        </Routes>
      </Suspense>
    </>
  );
};
