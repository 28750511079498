import React, { createContext, useState } from "react";

export const CampaignContext = createContext();

const CampaignContextProvider = ({ children }) => {
  const [campaign, setCampaign] = useState({});

  return (
    <CampaignContext.Provider value={{ campaign, setCampaign }}>
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignContextProvider;
