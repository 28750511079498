import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Form } from "react-bootstrap";

export default function EmbedSettings({ campaign, onUpdate }) {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [campaignData, setCampaignData] = useState(null);

  useEffect(() => {
    setCampaignData({
      type: campaign.type,
      game: campaign?.game,
    });
  }, [campaign]);

  const onChange = (e) => {
    const { name, value } = e.target;
    const data = { ...campaignData };
    data.game[name] = value;
    setCampaignData(data);
  };

  const onSubmit = async () => {
    setIsUpdateLoading(true);
    onUpdate("general", campaignData);
    setIsUpdateLoading(false);
  };

  return (
    <>
      <>
        <Card.Header className="card-header actions-toolbar border-0">
          <h5 className=" h6 mb-0">Game url settings</h5>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Url</Form.Label>
              <Form.Control
                type="text"
                name="url"
                placeholder="Insert your game url"
                defaultValue={campaign?.game?.url}
                onChange={onChange}
              />
            </Form.Group>
          </Form>
        </Card.Body>
        <Card.Footer>
          {!isUpdateLoading && (
            <Button variant="success" onClick={onSubmit}>
              Save
            </Button>
          )}
          {isUpdateLoading && (
            <Button variant="success" disabled>
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </Button>
          )}
        </Card.Footer>
      </>
    </>
  );
}
