/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import PageHelper from "../../helpers/pages";
import ReactTooltip from "react-tooltip";
import { PageContext } from "../../context/PageContext";

export default function TranslationList({ pageList, onNavigate }) {
  const [pages, setPages] = useState([]);
  const { setTranslationPage } = useContext(PageContext);
  const getPageSettings = (settings) => {
    const settingsListView = [];
    settingsListView.push(
      <>
        <i
          data-tip="Header Image"
          className="fas fa-image fa-lg"
          style={settings.header ? { color: "#5cb85c" } : {}}
        ></i>
        <i
          data-tip="Logo"
          className="fas fa-map-marker-alt fa-lg"
          style={settings.logo ? { color: "#5cb85c" } : {}}
        ></i>

        <i
          data-tip="Background Image"
          className="fas fa-layer-group fa-lg"
          style={settings.bg ? { color: "#5cb85c" } : {}}
        ></i>

        {settings.body !== undefined ? (
          <i
            data-tip="Body Image"
            className="fas fa-certificate fa-lg"
            style={settings.body ? { color: "#5cb85c" } : {}}
          ></i>
        ) : (
          <i
            className="fas fa-ban fa-lg"
            disabled
            style={settings.body ? { color: "#d9534f" } : {}}
          ></i>
        )}

        <i
          data-tip="Whatsapp"
          className="fab fa-whatsapp fa-lg"
          style={settings.whatsapp ? { color: "#5cb85c" } : {}}
        ></i>

        <i
          data-tip="Social"
          className="fas fa-hashtag fa-lg"
          style={settings.footer_social ? { color: "#5cb85c" } : {}}
        ></i>

        <i
          data-tip="Footer"
          className="fas fa-copyright fa-lg"
          style={settings.footer ? { color: "#5cb85c" } : {}}
        ></i>
        <ReactTooltip />
      </>
    );
    return settingsListView;
  };

  useEffect(() => {
    if (pageList) {
      const tables = [];
      const list = Object.entries(pageList);
      for (let i = 0; i < list.length; i++) {
        const page = list[i][0];
        tables.push(
          <tr key={i}>
            <th scope="row">
              <a
                type="button"
                onClick={() => {
                  setTranslationPage(list[i][1]);
                  onNavigate(page);
                }}
                className="name mb-0 h6 text-sm"
              >
                {PageHelper.getPageTitle(page)}
              </a>
            </th>
            <th>
              <div className="actions d-flex justify-content-between px-0">
                {getPageSettings(list[i][1].settings)}
              </div>
            </th>
          </tr>
        );
      }
      setPages(tables);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageList]);

  return (
    <div className="table-responsive">
      <table className="table align-items-center">
        <thead>
          <tr>
            <th scope="col" className="sort" data-sort="name">
              Pages
            </th>
            <th scope="col" className="sort" data-sort="name"></th>

            <th scope="col"></th>
          </tr>
        </thead>
        <tbody className="list">{pages}</tbody>
      </table>
    </div>
  );
}
