import React from "react";
import { Spinner } from "react-bootstrap";

export const LoadingWrapper = ({ background }) => {
  return (
    <div
      style={{
        position: "absolute",
        borderRadius:'12px',
        height: "100%",
        width: "100%",
        backgroundColor: `transparent`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: "0",
        right: "0",
        zIndex: "1000",
        transition: "all 2s",
      }}
    >
      <Spinner animation="grow" variant="warning" />
    </div>
  );
};
