import React from "react";
import { Spinner } from "react-bootstrap";

export const CardWrapper = () => {
  return (
    <div
      style={{
        height: "25vh",
        width: "100%",
        backgroundColor: `rgba(251, 240, 217, 0.02)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: "0",
        left: "0",
        zIndex: "1000",
        transition: "all 2s",
      }}
    >
      <Spinner animation="grow" variant="warning" />
    </div>
  );
};
