export const routeNames = {
  LOGIN: "/login",
  HOME: "/",
  SERVES: "/serves",
  DASHBOARD: "/:id/dashboard/",
  USERS: "/:id/users",
  SETTINGS: "/:id/settings",
  EMAIL: "/:id/email",
  BRANDING: "/:id/branding",
  TRANSLATION: "/:id/translation",
  TRANSLATION_PAGE: "/:id/translation/:translationId",
  RETAILERS: "/:id/retailers",
  RETAILER: "/:id/retailers/:retailerId",
  CAMPAIGN_SERVES: ":id/serves",
  CAMPAIGN_INSTANT_WIN: ":id/instant-win",
  CAMPAIGN_INSTANT_WIN_GROUP: ":id/instant-win/group/:groupId",
  CAMPAIGN_INSTANT_WIN_TRANSLATION_PAGE:
    "/:id/instant-win/translation/:translationId",
  INSTANT_WIN: ":id/external/instant",
  GAME_SETTINGS: ":id/game-settings",
  PREDICTION: ":id/prediction",
  PREDICTION_TRANSLATION_PAGE: "/:id/prediction/translation/:translationId",
  GAME_TRANSLATION_PAGE: "/:id/game/translation/:translationId",
  LOYALTY: ":id/loyalty",
  LOYALTY_TRANSLATION_PAGE: "/:id/loyalty/translation/:translationId",
};
