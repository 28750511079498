import { Navigate, useParams } from "react-router-dom";
import Header from "./Header";
import Navigation from "../components/Navigation";
import { Suspense, useContext, useEffect } from "react";
import { CampaignService } from "../services/CampaignService";
import { CampaignContext } from "../context/CampaignContext";
import { LoadingWrapper } from "./LoadingWrapper";

export default function PrivateRoute({ children }) {
  const params = useParams();
  const { setCampaign } = useContext(CampaignContext);
  useEffect(() => {
    CampaignService.getCampaignById(params?.id).then((res) => {
      if (res && res?.data) {
        setCampaign(res?.data);
      } else {
        window.location.replace("/");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return (
    <div className="container-fluid container-application">
      <Header activePage={window.location.pathname}></Header>
      <div className="main-content position-relative">
        <Navigation />

        <div className="page-content">
          <Suspense fallback={<LoadingWrapper />}>{children}</Suspense>
        </div>
      </div>
    </div>
  );
}
